import React from 'react';
import type { IconProps } from '../../../../../src/features/common/types/common';

const TopProjectIcon = ({ width }: IconProps) => {
  return (
    <svg
      width={width}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.12532 3.44832L5.21774 1.07156C5.27826 0.942321 5.38115 0.844603 5.50825 0.791016C5.63534 0.737428 5.77757 0.737428 5.90467 0.784711C6.03176 0.835146 6.13768 0.932865 6.20123 1.05895L7.35115 3.4105C7.43285 3.57756 7.58718 3.69104 7.76572 3.71941L10.2744 4.06931C10.4105 4.08822 10.5346 4.16072 10.6224 4.27105C10.7101 4.38138 10.7555 4.52007 10.7495 4.66507C10.7434 4.80692 10.6859 4.94247 10.5891 5.04334L8.80368 6.90314C8.67658 7.03553 8.61908 7.22781 8.64935 7.41379L9.10629 10.0081C9.1305 10.1499 9.10326 10.2949 9.03064 10.4147C8.95801 10.5376 8.84302 10.6259 8.70987 10.6637C8.57672 10.7015 8.43449 10.6858 8.31345 10.6227L6.06203 9.4249C5.90164 9.33979 5.70797 9.33979 5.55061 9.4312L3.3234 10.6795C3.20235 10.7488 3.06013 10.7677 2.92698 10.7331C2.79383 10.6984 2.67581 10.6133 2.60016 10.4935C2.52451 10.3737 2.49425 10.2287 2.51543 10.0869L2.91487 7.48314C2.94211 7.29716 2.88159 7.10803 2.75146 6.97879L0.92067 5.15997C0.823834 5.0591 0.763312 4.92355 0.751208 4.7817C0.74213 4.63986 0.784495 4.49801 0.869226 4.38453C0.953957 4.27105 1.07803 4.19855 1.2142 4.17648L3.71679 3.76985C3.89533 3.74148 4.04966 3.61854 4.12532 3.44832Z" />
    </svg>
  );
};

export default TopProjectIcon;
